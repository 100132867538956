import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`👹 Moderation`}</h1>
    <ul>
      <li parentName="ul">{`Moderation in Polis is available to and performed by the `}<a parentName="li" {...{
          "href": "/owners",
          "title": "owners"
        }}>{`owners`}</a>{` of each `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` via the `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{``}</li>
      <li parentName="ul">{`As each `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{` enters the system after being submitted by a `}<a parentName="li" {...{
          "href": "/participant",
          "title": "participant"
        }}>{`participant`}</a>{`, the `}<a parentName="li" {...{
          "href": "/owner",
          "title": "owner"
        }}>{`owner`}</a>{` will have the opportunity to approve or reject it, or leave it unmoderated.`}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` does employ `}<a parentName="li" {...{
          "href": "/comment-routing",
          "title": "comment routing"
        }}>{`comment routing`}</a>{` to intelligently choose `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` `}<a parentName="li" {...{
          "href": "/semi-randomly",
          "title": "semi-randomly"
        }}>{`semi-randomly`}</a>{`, to maximize `}<a parentName="li" {...{
          "href": "/participant",
          "title": "participant"
        }}>{`participant`}</a>{` time on meaningful tasks.`}</li>
      <li parentName="ul">{`Polis will work without moderation.`}
        <ul parentName="li">
          <li parentName="ul">{`You can, however, save a considerable amount of `}<a parentName="li" {...{
              "href": "/participant",
              "title": "participant"
            }}>{`participant`}</a>{` time and energy by moderating out nonsense or irrelevant `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` like`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`t4wrgdfsa`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`I am on the bus and looking at the sky while reading this`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul">{`These actions on your part are multiplicative: perhaps 1000's of `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` would have decided to `}<a parentName="li" {...{
              "href": "/pass",
              "title": "pass"
            }}>{`pass`}</a>{` on a `}<a parentName="li" {...{
              "href": "/comment",
              "title": "comment"
            }}>{`comment`}</a>{`, and now those 1000's of `}<a parentName="li" {...{
              "href": "/votes",
              "title": "votes"
            }}>{`votes`}</a>{` are going towards meaningful and relevant issues.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Moderation Schemes`}</h3>
    <ul>
      <li parentName="ul">{`There are two `}<a parentName="li" {...{
          "href": "/moderation-schemes",
          "title": "moderation schemes"
        }}>{`moderation schemes`}</a>{` schemes: `}<a parentName="li" {...{
          "href": "/strict-moderation",
          "title": "strict moderation"
        }}>{`strict moderation`}</a>{` and `}<a parentName="li" {...{
          "href": "/permissive-moderation",
          "title": "permissive moderation"
        }}>{`permissive moderation`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{`A `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` must choose one of the two moderation schemes, the default is strict`}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/strict-moderation",
              "title": "strict moderation"
            }}>{`strict moderation`}</a>{` is a whitelist model: no `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` are shown to `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` unless they have been accepted into the conversation by the moderator`}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/permissive-moderation",
              "title": "permissive moderation"
            }}>{`permissive moderation`}</a>{` is a blacklist model: all comments wil be shown to users as soon as they are submitted, but can be moderated out after the fact`}</li>
          <li parentName="ul">{`If you are embedding `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` on your site, it's a good idea to turn on strict moderation, as participants can, in effect, display profanity directly onto your digital property. It's a primary feature that what users type is turned right back around into what others vote on.`}</li>
          <li parentName="ul">{`Switch between `}<a parentName="li" {...{
              "href": "/strict-moderation",
              "title": "strict moderation"
            }}>{`strict moderation`}</a>{` and permissive moderation by toggling `}<a parentName="li" {...{
              "href": "/strict-moderation",
              "title": "strict moderation"
            }}>{`strict moderation`}</a>{` on and off in the `}<a parentName="li" {...{
              "href": "/configuration",
              "title": "configuration"
            }}>{`configuration`}</a>{` screen in the `}<a parentName="li" {...{
              "href": "/admin-interface",
              "title": "admin interface"
            }}>{`admin interface`}</a>{` for your `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{``}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Rubric`}</h3>
    <ul>
      <li parentName="ul">{`It is a `}<a parentName="li" {...{
          "href": "/preferred-practice",
          "title": "preferred practice"
        }}>{`preferred practice`}</a>{` to have a basic rubric for `}<a parentName="li" {...{
          "href": "/Moderation",
          "title": "👹 Moderation"
        }}>{`👹 Moderation`}</a>{`. This helps in communicating expectations to `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` (who do not get feedback about whether their `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{` was moderated in or out), and providing consistency. Here's a framework for creating one.`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` vote, on average, between 20 and 60 times, with some highly engaged conversations averaging over 100 votes per `}<a parentName="li" {...{
              "href": "/participant",
              "title": "participant"
            }}>{`participant`}</a>{`. There is a long tail on the distribution of votes: a vanishingly small percentage of `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` will `}<a parentName="li" {...{
              "href": "/vote",
              "title": "vote"
            }}>{`vote`}</a>{` on upwards of 600 to 800 comments. Still, a large `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` of thousands of `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` have 1000 or more comments.`}</li>
          <li parentName="ul">{`It is helpful to moderate out comments which are `}<strong parentName="li">{`spam`}</strong>
            <ul parentName="li">
              <li parentName="ul">{`Assume comment 1 says `}<inlineCode parentName="li">{`dfs34f43sdf`}</inlineCode></li>
              <li parentName="ul">{`Assume comment 2 says `}<inlineCode parentName="li">{`lol whatever`}</inlineCode></li>
              <li parentName="ul">{`Reject both of these comments`}</li>
            </ul>
          </li>
          <li parentName="ul">{`It is helpful to moderate out comments which are `}<strong parentName="li">{`duplicative`}</strong>{`.`}
            <ul parentName="li">
              <li parentName="ul">{`Assume comment 1 says `}<inlineCode parentName="li">{`We need better traffic management`}</inlineCode></li>
              <li parentName="ul">{`Assume comment 2 says `}<inlineCode parentName="li">{`Traffic management has been really bad`}</inlineCode></li>
              <li parentName="ul">{`If comment 1 has been accepted into the `}<a parentName="li" {...{
                  "href": "/conversation",
                  "title": "conversation"
                }}>{`conversation`}</a>{`, reject comment 2. Keeping duplicate statements out of the `}<a parentName="li" {...{
                  "href": "/conversation",
                  "title": "conversation"
                }}>{`conversation`}</a>{` maximizes the time and `}<a parentName="li" {...{
                  "href": "/votes",
                  "title": "votes"
                }}>{`votes`}</a>{` of `}<a parentName="li" {...{
                  "href": "/participants",
                  "title": "participants"
                }}>{`participants`}</a>{`.`}</li>
              <li parentName="ul">{`If the comments `}<strong parentName="li">{`are`}</strong>{` nuanced, and subtly different in terms of what they mean and intend, then, of course, allow them both in.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`It is helpful to moderate out comments which state `}<strong parentName="li">{`more than one idea`}</strong>{`, because it will confound analysis. It is difficult to tell which part of a comment a `}<a parentName="li" {...{
              "href": "/participant",
              "title": "participant"
            }}>{`participant`}</a>{` was agreeing or disagreeing to.`}
            <ul parentName="li">
              <li parentName="ul">{`Assume comment 1 says `}<inlineCode parentName="li">{`We really need to treat opioids as a health emergency rather than use the criminal justice system. Doctors who overprescribe should be imprisoned instead of addicts.`}</inlineCode></li>
              <li parentName="ul">{`Reject this comment, copy it, break it up into its parts and re-submit it in `}<a parentName="li" {...{
                  "href": "/seed-comments",
                  "title": "seed comments"
                }}>{`seed comments`}</a>{` as two separate comments.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`It is a `}<a parentName="li" {...{
          "href": "/preferred-practice",
          "title": "preferred practice"
        }}>{`preferred practice`}</a>{` to release the full data `}<a parentName="li" {...{
          "href": "/export",
          "title": "export"
        }}>{`export`}</a>{` as `}<a parentName="li" {...{
          "href": "/open-data",
          "title": "open data"
        }}>{`open data`}</a>{` when the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` is finished, and to let participants know this will happen. The data `}<a parentName="li" {...{
          "href": "/export",
          "title": "export"
        }}>{`export`}</a>{` does include all comments, and shows which were moderated out.`}</li>
    </ul>
    <h3>{`Closing commenting`}</h3>
    <ul>
      <li parentName="ul">{`If you are leaving your `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` open for weeks, after a while comments will get duplicative. It's a good idea to close commenting in `}<a parentName="li" {...{
          "href": "/configuration",
          "title": "configuration"
        }}>{`configuration`}</a>{` the `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{` at this time and let `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` know you will be doing so. That gives `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` time to come back and vote on all comments, and saves energy on the part of the moderators`}</li>
    </ul>
    <h3>{`Notes`}</h3>
    <ul>
      <li parentName="ul">{`Note that rejected comments cannot be moderated back into the conversation, but that you can copy and paste the text back in as a `}<a parentName="li" {...{
          "href": "/seed-comments",
          "title": "seed comments"
        }}>{`seed comments`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      